import React from 'react';

export default styles => (
	<div className='row'>
		<div className='col-sm-6'>
			<div className={styles.location}>
				<h2>Location</h2>
				<a target='_blank'
				href='https://www.google.com/maps/place/Pleasant+Hill+Church/@40.3091081,-84.8245865,15z/data=!4m5!3m4!1s0x0:0x6895a28d7c3bcff5!8m2!3d40.3091081!4d-84.8245865'>
					Pleasant Hill Church<br/>
					9945 N 800 E<br/>
					Union City, IN 47390
				</a>
			</div>
		</div>
		<div className='col-sm-6 '>

			<div className={styles.times}>
				<h2>Times</h2>
				<p>We have two Sunday worship services:</p>
				<p><b>9:00am</b> and <b>6:00pm</b><br/>
				(Sunday School at 10:30am)</p>

				<p>Join us Wednesdays for Prayer Meetings at <b>7:00pm</b></p>
			</div>
		</div>
	</div>
);
