import React from 'react'

import Layout            from '../components/layout'
import ContactHeroImage  from '../components/images/contact-hero-image'
import HeroWidget        from '../components/hero-widget';
// import ButterPageContent from '../components/butter-page-content';
import Footer            from '../components/footer'
import locationTimesPartial from '../components/location-times-partial';
import styles from './contact.module.scss';

//

const Page = () => (
	<Layout page="about">
		<div className={styles.page}>

			<HeroWidget image={ContactHeroImage}>
				<h1>Contact Us</h1>
			</HeroWidget>

			<section>
				<div className='row'>
					<div className='col-sm-6'>
						<div className={styles.email}>
							<h2>Email</h2>
							<a target='_blank'
							href='mailto:pastor@mypleasanthillchurch.org'>
								pastor@mypleasanthillchurch.org
							</a>
						</div>
					</div>
					<div className='col-sm-6 '>

						<div className={styles.phone}>
							<h2>Phone</h2>
							<p>765-964-3664</p>
						</div>
					</div>
				</div>

				{locationTimesPartial(styles)}

				<div className={styles.map}>
					<div className={styles.mapInner}>
						<iframe width="100%" height="100%" src="https://maps.google.com/maps?q=Pleasant%20Hill%20Church%2C%209954%20N%20800%20E%2C%20Union%20City%2C%20IN%2C%2048307&t=&z=13&ie=UTF8&iwloc=&output=embed" scrolling="no"></iframe>
					</div>
				</div>

			</section>

			<Footer/>
		</div>
	</Layout>
)

export default Page;
